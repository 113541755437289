import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../../components/shared/Layout';

const OplossingenPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-xl">
            <h1 className="mb-16">Oplossingen</h1>

            <p>
              Met Haagsche Schil zijn we onderdeel van het circulaire netwerk
              van de stad. We zorgen er samen voor dat afval geminimaliseerd
              wordt en er zo veel mogelijk wordt hergebruik. Door zoveel
              mogelijk gescheiden afvalstromen te realiseren wordt hergebruik
              haalbaar.
            </p>

            <p>
              In samenwerking met onze circulaire partners richten we ons op een
              betere verwerking van organische reststromen in de stad.
            </p>

            <p className="mb-16">
              Dit is een cirulair proces. Van ophalen, opslaan en verwerken, tot
              het ontwikkelen van nieuwe producten en de verkoop hiervan.
            </p>
            <h2 className="mb-16">
              <Link to="/oplossingen/voor-bewoners">Voor bewoners</Link>
            </h2>
            <h2 className="mb-16">
              <Link to="/oplossingen/voor-organisaties">Voor organisaties</Link>
            </h2>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default OplossingenPage;
